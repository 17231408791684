// Import the functions you need from the SDKs you need
// prettier-ignore
/* eslint-disable prettier/prettier */
// firebase.js
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
// Importa getAnalytics solo si estás utilizando Google Analytics
import { Capacitor } from '@capacitor/core';
import { PushNotifications } from '@capacitor/push-notifications';
import { NotificationManager } from 'components/common/react-notifications';

const firebaseConfig = {
    apiKey: 'AIzaSyDXpIqhIdM4O4_Na2z0-B8-rxLXdF8mUxI',
    authDomain: 'cpxapp-320623.firebaseapp.com',
    projectId: 'cpxapp-320623',
    storageBucket: 'cpxapp-320623.appspot.com',
    messagingSenderId: '705212848261',
    appId: '1:705212848261:web:f1737324529f5b37e2b1ec',
    measurementId: 'G-CN4EPY7K9B', // Solo si utilizas Google Analytics
};

// Inicializa Firebase
const app = initializeApp(firebaseConfig);
// Inicializa Firebase Cloud Messaging solo si estás en web
let messaging;
if (!Capacitor.isNativePlatform()) {
    messaging = getMessaging(app);
}

// Si usas Google Analytics, inicializa aquí

// Solicitar el token para las notificaciones
export const requestForToken = async () => {
    try {
        if (Capacitor.isNativePlatform()) {
            // Si es Android, usa Capacitor para obtener el token
            await PushNotifications.requestPermissions();
            await PushNotifications.register();
            
            return new Promise((resolve, reject) => {
                PushNotifications.addListener('registration', (token) => {
                  /* console.log('Token de registro en Android: ', token.value); */
                  resolve(token.value); // Resuelve la promesa con el token
                });
          
                PushNotifications.addListener('registrationError', (error) => {
                  console.error('Error al registrar en Android: ', error);
                  reject(error); // Rechaza la promesa en caso de error
                });
              });
        } 
        const token = await getToken(messaging, { vapidKey: 'BAmSlRdPUXRE4FS3UUowBcPQQ-gSBY7uRyox7YkOXr-diG99WK64KdALceiJnqz-xAnhVGaV3-XwixImXprcSLA' });
            /* console.log('Token de registro en Web: ', token); */
            return token;
    } catch (error) {
        console.error('Error al obtener el token en android wcampos:', error);
        return null;
    }
};

const showWebNotification = (notification) => {
    // Verificar si el navegador soporta notificaciones
    if ("Notification" in window) {
        // Solicitar permiso para notificaciones si no está concedido
        Notification.requestPermission().then((permission) => {
            if (permission === "granted") {
                // Mostrar la notificación
                // eslint-disable-next-line no-new
                new Notification(notification.title, {
                    body: notification.body,
                    icon: '/icon.png'  // Puedes reemplazar con tu propio ícono
                });
            } else {
                console.log("Permiso de notificación denegado");
            }
        });
    } else {
        console.log("Este navegador no soporta notificaciones.");
    }
};
// Mostrar alerta para Android
const showAndroidAlert = (notification) => {
    // Mostrar una alerta simple con el título y el cuerpo de la notificación
    NotificationManager.success(`${notification.title}\n${notification.body}`, "", 5000, null, null, 'filled');
};
// Escuchar mensajes en primer plano
export const listenForMessages = () => {
    if (!Capacitor.isNativePlatform()) {
        onMessage(messaging, (payload) => {
            /* console.log('Mensaje recibido en Web: Escuchar mensajes en primer plano', payload); */
            // Aquí puedes manejar la notificación en la UI
            if (payload.notification) {
                showWebNotification(payload.notification);
            }
        });
    } else {
        // Aquí puedes manejar las notificaciones de Android si es necesario
       // Aquí manejamos las notificaciones de Android
       PushNotifications.addListener('pushNotificationReceived', (notification) => {
        console.log('Notificación recibida en Android: Escuchar mensajes en primer plano', notification);
        // Mostrar alerta en Android
        if (notification.title && notification.body) {
            showAndroidAlert(notification);
        }
    });
    }
};

export const onMessageListener = () =>
    new Promise((resolve) => {
        if (!Capacitor.isNativePlatform()) {
            onMessage(messaging, (payload) => {
                console.log("Mensaje recibido en Web: ", payload);
                // Mostrar notificación en la UI
                if (payload.notification) {
                    showWebNotification(payload.notification);
                }
                resolve(payload);
            });
        } else {
            PushNotifications.addListener('pushNotificationReceived', (notification) => {
                console.log('Notificación recibida en Android: ', notification);
                resolve(notification);
            });
        }
    });
